import { template as template_c36f7245b2854df79c013d118c3963bb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_c36f7245b2854df79c013d118c3963bb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
