import { template as template_13460a2e299a4d4a9aeceba5b98a5d7b } from "@ember/template-compiler";
const WelcomeHeader = template_13460a2e299a4d4a9aeceba5b98a5d7b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
