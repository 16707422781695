import { template as template_8f9f6c6730aa4881a2940a58cfd491e6 } from "@ember/template-compiler";
const FKText = template_8f9f6c6730aa4881a2940a58cfd491e6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
