import { template as template_43ba425dce394f66b5dc6b2faa82314a } from "@ember/template-compiler";
const FKControlMenuContainer = template_43ba425dce394f66b5dc6b2faa82314a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
