import { template as template_5eb62651af9546a4b37c965ccd604bd6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5eb62651af9546a4b37c965ccd604bd6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
